<template>
    <div :id="blok._uid"
        class="col-span-full flex w-full flex-col justify-center px-sm py-xl md:px-0">
        <div class="w-full ">
            <p class="text-center font-prenton-cond text-5xl font-bold uppercase">{{ blok.title }}</p>
        </div>

        <div class="grid gap-x-sm"
            :class="gridRows">
            <div v-for="(content, index) in blok.content"
                :key="index">
                <v-storyblok-component :blok="content" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-product-safety' | 'sb-product-safety-container' }>
}>();

const gridRows = computed(() => `grid-cols-2 lg:grid-cols-${properties.blok.content.length}`);
</script>
